import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Button, Card, List, Modal } from 'antd'
import ContactUsSection from '../../containers/ContactUs'

import { MissionCardContentWrapper } from './sidebar.style'

import BannerBG from '../../../assets/image/banner-texture.png'

const sidebarCardTitleStyle = {
  color: '#ffffff',
  fontSize: '1.50rem',
  fontWeight: 700,
  lineHeight: 1.2,
  letterSpacing: '-0.025em',
  background: `linear-gradient(45deg, rgba(99, 23, 25, 0.8) 0%, rgba(176, 58, 62, 0.7) 75%), url(${BannerBG})`,
  maxWidth: '100vw',
}

const sidebarCardStyle = {
  marginTop: '1rem',
  marginRight: '2rem',
  marginBottom: '1rem',
  marginLeft: '1rem',
}

const sidebarContactUsCardStyle = {
  marginTop: '1rem',
  marginBottom: '1rem',
  marginLeft: '-0.5rem',
  marginRight: '0.5rem',
}

const sidebarContactUsButtonStyle = {
  backgroundColor: '#1790FF',
  color: '#ffffff',
}

const sidebarListItemLinkStyle = {
  color: '#1890ff',
  fontSize: '1.025rem',
}

export const Sidebar = ({ services, blogs }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Card title='Our Mission' headStyle={sidebarCardTitleStyle} style={sidebarCardStyle}>
        <MissionCardContentWrapper>
          <div>
            At RAVENii we leverage the collective intelligence of security experts across the globe to provide peace of mind that your business will continue to
            run efficiently in a secure environment that is right for you.
          </div>
        </MissionCardContentWrapper>
      </Card>

      <Card title='Services' headStyle={sidebarCardTitleStyle} style={sidebarCardStyle}>
        <List
          header={null}
          footer={null}
          dataSource={services}
          renderItem={item => (
            <List.Item>
              <Link to={`/services${item.fields.slug}`} style={sidebarListItemLinkStyle}>
                {item.frontmatter.title}
              </Link>
            </List.Item>
          )}
        />
      </Card>

      <Card bordered={false} style={sidebarContactUsCardStyle}>
        <Button block={true} size='large' style={sidebarContactUsButtonStyle} onClick={openModal}>
          Contact Us Now
        </Button>
      </Card>

      <Card title='Blog' headStyle={sidebarCardTitleStyle} style={sidebarCardStyle}>
        <List
          header={null}
          footer={null}
          dataSource={blogs}
          renderItem={item => (
            <List.Item>
              <Link to={`/blog${item.fields.slug}`} style={sidebarListItemLinkStyle}>
                {item.frontmatter.title}
              </Link>
            </List.Item>
          )}
        />
        <Button block={true} type='primary' size='large' ghost style={{ marginTop: '1rem' }}>
          <Link to={`/blog`}>View All Blogs</Link>
        </Button>
      </Card>

      <Modal visible={isModalOpen} onCancel={closeModal} footer={null}>
        <ContactUsSection />
      </Modal>
    </>
  )
}
