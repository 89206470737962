import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '../../atoms/Box'
import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import Container from '../../common/UI/Container'
import { Alert, Checkbox, Col, Input, Radio, Row, Space } from 'antd'
import * as yaml from 'js-yaml'

const PenTestVulnAssessment = ({ sectionWrapper, row, col, secTitleWrapper, secHeading, secText }) => {
  const [formValues, setFormValues] = useState({})
  const [submitted, setSubmitted] = useState(false)

  const onFormValueChange = event => {
    const target = event.target
    const isDomElement = target instanceof Element
    const section = isDomElement ? target.getAttribute('data-formsection') : target['data-formsection']
    const formProp = isDomElement ? target.getAttribute('data-formprop') : target['data-formprop']
    const formValuesRef = formValues

    let isAdd = true
    if (target.type === 'checkbox') {
      isAdd = target.checked
    } else if (target.type === 'radio') {
      isAdd = target.checked
    } else if (target.type === 'text') {
      isAdd = target.value ? true : false
    }

    if (isAdd) {
      if (!formValuesRef[section]) {
        formValuesRef[section] = {}
      }

      formValuesRef[section][formProp] = event.target.value
    } else {
      delete formValuesRef[section][formProp]

      if (Object.keys(formValuesRef[section]).length === 0) {
        delete formValuesRef[section]
      }
    }

    setFormValues(formValuesRef)
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const handleSubmit = event => {
    const form = event.target
    const formName = form.getAttribute('name')

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': formName,
        answers: yaml.dump(formValues),
      }),
    }).catch(error => console.error(error))

    setSubmitted(true)
    setTimeout(() => {
      setSubmitted(false)
    }, 5000)

    event.preventDefault()
  }

  return (
    <Box {...sectionWrapper} id='pentestvulnassessment_section'>
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content='PENETRATION TESTING / VULNERABILITY ASSESSMENT SCOPING QUESTIONNAIRE' />
        </Box>
        <Box {...row}>
          <Box {...col}>
            <div style={{ maxWidth: '800px', margin: 'auto' }}>
              {!submitted ? (
                <form name='pentestvulnassessment' method='post' data-netlify='true' data-netlify-honeypot='bot-field' onSubmit={handleSubmit}>
                  <input type='hidden' name='form-name' value='pentestvulnassessment' />

                  <div style={{ marginBottom: '20px' }}>
                    <Text content='Type of service engagement?' fontWeight='bold' />
                    <Checkbox.Group style={{ width: '100%' }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox
                            label='Penetration test'
                            value='service engagement - penetration test'
                            data-formsection='Service Engagement Type'
                            data-formprop='penetrationTest'
                            onChange={onFormValueChange}
                          >
                            Penetration test – Identify exploits, simulate attack against network infrastructure, applications, etc.
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox
                            label='Vulnerability assessment'
                            value='service engagement - vulnerability assessment'
                            data-formsection='Service Engagement Type'
                            data-formprop='vulnerabilityAssessment'
                            onChange={onFormValueChange}
                          >
                            Vulnerability assessment – Identify and report vulnerabilities in network infrastructure, applications, etc. Risk mapping and
                            remediation guidance.
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Text content='Type of test / assessment?' fontWeight='bold' />
                    <Checkbox.Group style={{ width: '100%' }}>
                      <Row>
                        <Col span={8}>
                          <Checkbox
                            label='Internal network'
                            value='assessment type - internal network'
                            data-formsection='Assessment Type'
                            data-formprop='internalNetwork'
                            onChange={onFormValueChange}
                          >
                            Internal network
                          </Checkbox>
                        </Col>
                        <Col span={8} style={{ padding: 2 }}>
                          <Input
                            label='Number of locations'
                            placeholder='Number of Locations'
                            data-formsection='Assessment Type'
                            data-formprop='internalNetworkNumberOfLocations'
                            onChange={onFormValueChange}
                          />
                        </Col>
                        <Col span={8} style={{ padding: 2 }}>
                          <Input
                            label='Number of IPs'
                            placeholder='Number of IPs'
                            data-formsection='Assessment Type'
                            data-formprop='internalNetworkNumberOfIps'
                            onChange={onFormValueChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <Checkbox
                            label='External network'
                            value='assessment type - external network'
                            data-formsection='Assessment Type'
                            data-formprop='externalNetwork'
                            onChange={onFormValueChange}
                          >
                            External network
                          </Checkbox>
                        </Col>
                        <Col span={8} style={{ padding: 2 }}>
                          <Input
                            label='Number of locations'
                            placeholder='Number of locations'
                            data-formsection='Assessment Type'
                            data-formprop='externalNetworkNumberOfLocations'
                            onChange={onFormValueChange}
                          />
                        </Col>
                        <Col span={8} style={{ padding: 2 }}>
                          <Input
                            label='Number of IPs'
                            placeholder='Number of IPs'
                            data-formsection='Assessment Type'
                            data-formprop='externalNetworkNumberOfIps'
                            onChange={onFormValueChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <Checkbox
                            label='Application'
                            value='assessment type - application'
                            data-formsection='Assessment Type'
                            data-formprop='application'
                            onChange={onFormValueChange}
                          >
                            Application
                          </Checkbox>
                        </Col>
                        <Col span={8} style={{ padding: 2 }}>
                          <Input
                            label='Number of apps'
                            placeholder='Number of apps'
                            data-formsection='Assessment Type'
                            data-formprop='applicationNumberOfApps'
                            onChange={onFormValueChange}
                          />
                        </Col>
                        <Col span={8} style={{ padding: 2 }}>
                          <Input
                            label='Number of APIs'
                            placeholder='Number of APIs'
                            data-formsection='Assessment Type'
                            data-formprop='applicationNumberOfApis'
                            onChange={onFormValueChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <Checkbox
                            label='Hardware'
                            value='assessment type - hardware'
                            data-formsection='Assessment Type'
                            data-formprop='hardware'
                            onChange={onFormValueChange}
                          >
                            Hardware
                          </Checkbox>
                        </Col>
                        <Col span={16} style={{ padding: 2 }}>
                          <Input
                            label='Type and quantity'
                            placeholder='Type and quantity'
                            data-formsection='Assessment Type'
                            data-formprop='hardwareTypeAndQuantity'
                            onChange={onFormValueChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <Checkbox
                            label='Wi-Fi'
                            value='assessment type - wifi'
                            data-formsection='Assessment Type'
                            data-formprop='wifi'
                            onChange={onFormValueChange}
                          >
                            Wi-Fi
                          </Checkbox>
                        </Col>
                        <Col span={8} style={{ padding: 2 }}>
                          <Input
                            label='Number of locations'
                            placeholder='Number of locations'
                            data-formsection='Assessment Type'
                            data-formprop='wifiNumberOfLocations'
                            onChange={onFormValueChange}
                          />
                        </Col>
                        <Col span={8} style={{ padding: 2 }}>
                          <Input
                            label='Number of networks'
                            placeholder='Number of networks'
                            data-formsection='Assessment Type'
                            data-formprop='wifiNumberOfNetworks'
                            onChange={onFormValueChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <Checkbox
                            label='Other'
                            value='assessment type - other'
                            data-formsection='Assessment Type'
                            data-formprop='other'
                            onChange={onFormValueChange}
                          >
                            Other
                          </Checkbox>
                        </Col>
                        <Col span={16} style={{ padding: 2 }}>
                          <Input
                            label='Provide details'
                            placeholder='Provide details'
                            data-formsection='Assessment Type'
                            data-formprop='otherDetails'
                            onChange={onFormValueChange}
                          />
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Text content='Reason for testing / assessment?' fontWeight='bold' />
                    <Checkbox.Group style={{ width: '100%' }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox
                            label='Current security concern'
                            value='assessment reason - security concern'
                            data-formsection='Assessment Reason'
                            data-formprop='securityConcern'
                            onChange={onFormValueChange}
                          >
                            Current security concern
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox
                            label='Regulatory/compliance requirement'
                            value='assessment reason - compliance requirement'
                            data-formsection='Assessment Reason'
                            data-formprop='complianceRequirement'
                            onChange={onFormValueChange}
                          >
                            Regulatory/compliance requirement (HIPPA, PCI, etc)
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox
                            label='Validate security programs/controls'
                            value='assessment reason - validate programs'
                            data-formsection='Assessment Reason'
                            data-formprop='validatePrograms'
                            onChange={onFormValueChange}
                          >
                            Validate security programs/controls
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox
                            label='Validate service provider'
                            value='assessment reason - validate service provider'
                            data-formsection='Assessment Reason'
                            data-formprop='validateServiceProvider'
                            onChange={onFormValueChange}
                          >
                            Validate service provider (MSP/MSSP)
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox
                            label='Other'
                            value='assessment reason - other'
                            data-formsection='Assessment Reason'
                            data-formprop='other'
                            onChange={onFormValueChange}
                          >
                            Other
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Text content='Test environment' fontWeight='bold' />
                    <Checkbox.Group style={{ width: '100%' }}>
                      <Row>
                        <Col span={24}>
                          <Checkbox
                            label='Production'
                            value='test environment - production'
                            data-formsection='Test Environment'
                            data-formprop='production'
                            onChange={onFormValueChange}
                          >
                            Production
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox
                            label='Staging/QA'
                            value='test environment - staging'
                            data-formsection='Test Environment'
                            data-formprop='staging'
                            onChange={onFormValueChange}
                          >
                            Staging/QA
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox
                            label='Development'
                            value='test environment - development'
                            data-formsection='Test Environment'
                            data-formprop='development'
                            onChange={onFormValueChange}
                          >
                            Development
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Text content='Client industry?' fontWeight='bold' />
                    <Input label='Client industry' data-formsection='Client Industry' data-formprop='clientIndustry' onChange={onFormValueChange} />
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Text content='Is letter of attestation desired?' fontWeight='bold' />
                    <Radio.Group>
                      <Space direction='vertical'>
                        <Radio value='true' data-formsection='Is Attestation Desired' data-formprop='attestationDesired' onChange={onFormValueChange}>
                          Yes
                        </Radio>
                        <Radio value='false' data-formsection='Is Attestation Desired' data-formprop='attestationDesired' onChange={onFormValueChange}>
                          No
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Text content='Will retesting be in scope (validation of successful remediation of findings)?' fontWeight='bold' />
                    <Radio.Group>
                      <Space direction='vertical'>
                        <Radio value='true' data-formsection='Is Retesting In Scope' data-formprop='retestingInScope' onChange={onFormValueChange}>
                          Yes
                        </Radio>
                        <Radio
                          value='false'
                          data-formsection='Is Retesting In Scope'
                          data-formprop='retestingInScope'
                          onChange={onFormValueChange}
                          name='retesting in scope'
                        >
                          No
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Text content='Submitted By' fontWeight='bold' />
                    <Input label='Submitted By' data-formsection='Submitted By' data-formprop='submittedBy' onChange={onFormValueChange} />
                  </div>

                  <div style={{ width: '100%' }}>
                    <Button style={{ width: '100%', fontSize: '20px' }} title='Submit' type='submit' />
                  </div>
                </form>
              ) : (
                <Alert message='Questionnaire successfully submitted.' type='success' />
              )}
            </div>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

PenTestVulnAssessment.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
}

PenTestVulnAssessment.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['60px', '70px', '90px', '90px'],
    pb: ['30px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['55px', '65px'],
    ml: '75px',
    mr: '75px',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  col: {
    width: ['100%', '100%', '75%', '75%'],
  },
}

export default PenTestVulnAssessment
