import React from 'react'
import { graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import Sticky from 'react-stickynode'

import { raveniiTheme } from '../theme/ravenii'
import { ResetCSS } from '../assets/css/style'
import { GlobalStyle, ContentWrapper } from '../components/layout/container.style'

import { DrawerProvider } from '../components/common/contexts/DrawerContext'
import Seo from '../components/seo'
import Navbar from '../components/layout/Navbar'
import { ServicePage } from '../components/common/service/ServicePage'
import ContactUsSection from '../components/containers/ContactUs'
import Footer from '../components/layout/Footer'

export default function Template({ data }) {
  const { markdownRemark, blogs, services } = data

  return (
    <ThemeProvider theme={raveniiTheme}>
      <>
        <Seo title={`RAVENii | ${markdownRemark.frontmatter.title}`} />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <ServicePage markdownRemark={markdownRemark} blogs={blogs} services={services} />

          <ContactUsSection />

          <Footer />
        </ContentWrapper>
      </>
    </ThemeProvider>
  )
}

export const pageQuery = graphql`
  query ServicesBySlug($id: String!, $previousServiceId: String, $nextServiceId: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        active
        headerImage {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousServiceId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextServiceId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }

    blogs: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { fields: { collection: { eq: "blog" } } }, limit: 3) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }

    services: allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }, filter: { fields: { collection: { eq: "services" } } }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`
