import styled from 'styled-components'
import { space } from 'styled-system'
import BannerBG from '../../../../assets/image/banner-texture.png'

export const ParallaxImageTextOverlayWrapper = styled.section`
  color: #ffffff;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.025em;
  text-align: center;
  padding: 15px 40px 15px 40px;
  background-image: url(${BannerBG}), linear-gradient(35deg, rgba(99, 23, 25, 0.7) 0%, rgba(176, 58, 62, 0.7) 100%);
  width: 100%;

  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const ParallaxImageWrapper = styled.div`
  height: 285px;
`
export const ServiceContentWrapper = styled.div`
  font-size: 1.1rem;
  margin: 1.33rem;
`

export const ServicePageContent = styled.div`
  li {
    margin-left: 20px;
    list-style-type: disc;
    list-style-position: outside;
  }
  blockquote {
    margin: 18px;
  }
  hr {
    height: 0.033rem;
    margin-bottom: 2.5rem;
  }
  ${space}
`
