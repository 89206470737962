import React, { useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import { Parallax } from 'react-parallax'
import { Sidebar } from '../../Sidebar'
import { ParallaxImageTextOverlayWrapper, ParallaxImageWrapper, ServiceContentWrapper, ServicePageContent } from './servicePage.style'
import ServiceHeaderImage from '../../../../images/service-header-image.png'
import PenTestVulnAssessment from '../../../containers/PenTestVulnAssessment'

export const ServicePage = ({ markdownRemark, blogs, services }) => {
  const { frontmatter, html } = markdownRemark
  const pageSlug = markdownRemark?.fields?.slug
  const [isPenAssessmentFormModalOpen, setIsPenAssessmentFormModalOpen] = useState(false)

  const showPenAssessmentFormModal = () => {
    setIsPenAssessmentFormModalOpen(true)
  }

  const handleCancel = () => {
    setIsPenAssessmentFormModalOpen(false)
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Parallax bgImage={ServiceHeaderImage} strength={75}>
            <ParallaxImageWrapper>
              <ParallaxImageTextOverlayWrapper>
                <div>{frontmatter.title}</div>
              </ParallaxImageTextOverlayWrapper>
            </ParallaxImageWrapper>
          </Parallax>
        </Col>
      </Row>

      <Row>
        <Col md={24} lg={18}>
          <Card bordered={false}>
            <ServiceContentWrapper>
              <ServicePageContent>
                {pageSlug && pageSlug === '/tests-and-audits/' ? (
                  <>
                    <Row>
                      <Col span={24}>
                        <Button
                          size='large'
                          style={{ width: '100%', marginTop: '16px', marginBottom: '16px' }}
                          type='primary'
                          onClick={showPenAssessmentFormModal}
                        >
                          <FormOutlined /> Click here to fill out the penetration testing / vulnerability assessment scoping questionnaire
                        </Button>
                        <Modal style={{ minWidth: '50%' }} visible={isPenAssessmentFormModalOpen} onCancel={handleCancel} footer={null}>
                          <PenTestVulnAssessment />
                        </Modal>
                      </Col>
                    </Row>
                  </>
                ) : null}

                <div dangerouslySetInnerHTML={{ __html: html }} />
              </ServicePageContent>
            </ServiceContentWrapper>
          </Card>
        </Col>
        <Col md={24} lg={6}>
          <Sidebar services={services.nodes} blogs={blogs.nodes}></Sidebar>
        </Col>
      </Row>
    </>
  )
}
